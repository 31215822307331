import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('position', page, perPage, search, sortBy, descending, params);
}

function autocomplete(page, perPage, search, sortBy, descending, params) {
  return getPaginated('position/autocomplete', page, perPage, search, sortBy, descending, params);
}

/**
 * @param positionId {number}
 */
function show(positionId) {
  return get(`position/${positionId}`);
}

/**
 * @param position {Position}
 */
function create(position) {
  return post('position', position);
}

/**
 * @param position {Position}
 */
function update(position) {
  return put(`position/${position.id}`, position);
}

/**
 * @param positionId {number}
 */
function remove(positionId) {
  return destroy(`position/${positionId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
  autocomplete,
};
