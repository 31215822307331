
import Vue, { VueConstructor } from 'vue';
import Field from '@/components/crud/fields/FieldMixin.vue';
// @ts-ignore: convert dayjs to typescript
import dayjs from '@/plugins/dayjs';
import { Dayjs } from 'dayjs';

interface ComponentData {
  menu: boolean,
  date: string,
  activePicker: string | null,
  overrideRequired: boolean,
}

export default (Vue as VueConstructor<Vue & {
  $refs: {
    field: HTMLFormElement
  },
  // todo: is this the right way to solve this?
  computedLabel: string
}>).extend({
  name: 'KDateField',
  mixins: [Field],
  props: {
    value: {
      type: String,
    },
    minDate: {
      type: [String, dayjs],
    },
    maxDate: {
      type: [String, dayjs],
    },
    // use to choose a month instead of a day
    // datepicker will start with years to choose from and then months
    useMonthType: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: (): ComponentData => ({
    menu: false,
    date: '',
    activePicker: null,
    overrideRequired: false,
  }),
  computed: {
    format(): string {
      return this.useMonthType ? 'YYYY-MM' : 'YYYY-MM-DD';
    },
    type(): string {
      return this.useMonthType ? 'month' : 'date';
    },
    // override FieldMixin computed required rule
    requiredRule(): ((v: unknown) => true | string) | void {
      if (this.overrideRequired) {
        return;
      }
      return (v: unknown): true | string => (!!v || v === 0 || v === false) || this.$t('validation.required', { field: this.computedLabel }) as string;
    },
    computedMinDate() {
      return dayjs(this.minDate || '1900-01-01');
    },
    computedMaxDate() {
      return dayjs(this.maxDate || '2199-12-31');
    },
  },
  watch: {
    value: {
      handler(value: string | Dayjs): void {
        if (!!this.safeDateValue(value as string) || !value) {
          //@ts-ignore
          this.date = value && this.setDateString(this.safeDateValue(value));
        }
      },
      immediate: true,
    },
    menu(val: boolean): void {
      // override required validation when menu is open to avoid required feedback glitch
      this.overrideRequired = val;
      val && setTimeout(() => (this.activePicker = this.useMonthType ? 'YEAR' : 'DATE'));
    },
  },
  methods: {
    onInputChange(value: string): void {
      this.updateDateProp(value);
    },
    onInputClick(e: MouseEvent): void {
      // don't open default menu on click (firefox)
      e.preventDefault();
    },
    onDatePickerChange(date: string): void {
      this.updateDateProp(date);
      this.menu = false;
    },
    async updateDateProp(date: string): Promise<void> {
      if (!!this.safeDateValue(date) || !date) {
        //@ts-ignore
        await this.$emit('input', date && dayjs(this.safeDateValue(date)).format('YYYY-MM-DD'));
        this.$refs.field.validate();
      }
    },
    onInputKeyDown(e: KeyboardEvent): void {
      if (e.code === 'Space') {
        // prevent default date picker and open v-datepicker instead
        e.preventDefault();
        // this.menu = true;
      }
    },
    validateDate(date: string): string | boolean {
      if (!date) return true;
      if (dayjs(date).isBefore(this.computedMinDate, 'day')) return this.$t('validation.minDate', { date: this.computedMinDate.format('DD-MM-YYYY') }) as string;
      if (dayjs(date).isAfter(this.computedMaxDate, 'day')) return this.$t('validation.maxDate', { date: this.computedMaxDate.format('DD-MM-YYYY') }) as string;
      return dayjs(date).isValid() || this.$refs?.field?.hint || '';
    },
    setDateString(date: string): string {
      return date && dayjs(date).format(this.format);
    },
    safeDateValue(value: string): boolean | Dayjs {
      const date = dayjs(new Date(value));
      return date.isValid() && date;
    },
  },
});
