<template>
  <widget
    v-if="candidate.maxTravelingTime"
    :title="$t('candidate.widgets.timeTravel.title')"
  >
    <k-field-group language-prefix="candidate.widgets.timeTravel">
      <v-form
        ref="form"
        v-model="isValid"
        @submit.prevent="calculateDistance"
      >
        <v-row>
          <v-col
            class="flex-column"
            lg="5"
            sm="12"
          >
            <k-text-display :field="$t('candidate.widgets.timeTravel.maxTravelTime')">
              {{ candidate.maxTravelingTime }} {{ $tc('global.timeUnits.minute', candidate.maxTravelingTime) }}
            </k-text-display>
            <k-text-display
              :icon-color="candidate.transportationCar ? 'grey darken-2' : 'grey lighten-2'"
              icon="$car"
            >
              {{ $t('candidate.widgets.timeTravel.transportationCar') }}
            </k-text-display>
            <k-text-display
              :icon-color="candidate.transportationPublic ? 'grey darken-2' : 'grey lighten-2'"
              icon="$train"
            >
              {{ $t('candidate.widgets.timeTravel.transportationPublic') }}
            </k-text-display>
          </v-col>
          <v-col
            cols="12"
            lg="7"
            sm="12"
          >
            <v-row dense>
              <v-col class="flex-grow-1 flex-shrink-0 pl-0">
                <KTextField
                  v-model="travelForm.destination"
                  append-icon="$address"
                  class="pt-2"
                  field="Destination"
                  required
                />
              </v-col>
            </v-row>
            <v-row
              class="align-baseline"
              dense
            >
              <KDateField
                ref="dateField"
                v-model="travelDate"
                :max-date="maxDate"
                :min-date="minDate"
                :style="{ width: '164px' }"
                field="Arrival"
                grid="pr-2"
                required
              />
              <KTimeField
                v-model="travelTime"
                :style="{ width: '112px' }"
                field="Time"
                format="HH:mm"
                grid="pr-2"
                required
              />
              <v-btn
                :loading="isLoading"
                depressed
                outlined
                tile
                class="mx-1"
                color="primary"
                grid="pr-2"
                type="submit"
              >
                {{ $t('candidate.widgets.timeTravel.calculate') }}
              </v-btn>
            </v-row>
            <v-row
              v-if="travelInfo.driving"
              dense
            >
              <v-col v-if="candidate.transportationCar">
                <k-text-display
                  :icon-color="compareWithMaxTravelTime('driving') ? 'green accent-3' : 'red darken-2'"
                  icon="$car"
                >
                  <v-row
                    class="flex-column"
                    no-gutters
                  >
                    <v-col
                      v-for="(item, index) in formatTimeTravelTextI18n('driving')"
                      :key="index"
                    >
                      {{ item }}
                    </v-col>
                  </v-row>
                </k-text-display>
              </v-col>
            </v-row>
            <v-row
              v-if="travelInfo.transit"
              dense
            >
              <v-col v-if="candidate.transportationPublic">
                <k-text-display
                  :icon-color="compareWithMaxTravelTime('transit') ? 'green accent-3' : 'red darken-2'"
                  icon="$train"
                >
                  <v-row
                    class="flex-column"
                    no-gutters
                  >
                    <v-col
                      v-for="(item, index) in formatTimeTravelTextI18n('transit')"
                      :key="index"
                    >
                      {{ item }}
                    </v-col>
                  </v-row>
                </k-text-display>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </k-field-group>
  </widget>
</template>

<script>
import { TravelDistance } from '@/application/models/TravelDistance.js';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KTimeField from '@/components/crud/fields/KTimeField.vue';
import Widget from '@/components/widgets/KWidget.vue';
import { calculateTravelInfo } from '@/modules/candidate/api/candidate.js';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'TravelDistance',
  components: {
    KFieldGroup,
    KTextField,
    KTimeField,
    KDateField,
    Widget,
    KTextDisplay,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      isLoading: false,
      travelDestination: '',
      currentRoute: {},
      travelForm: new TravelDistance(),
      travelTime: dayjs().format('HH:mm'),
      travelDate: dayjs().format('YYYY-MM-DD'),
      travelInfo: {},
    };
  },
  computed: {
    minDate() {
      return dayjs();
    },
    maxDate() {
      return dayjs().add(1, 'year');
    },
  },
  created() {
    this.travelForm.driving_mode = this.candidate.transportationCar;
    this.travelForm.transit_mode = this.candidate.transportationPublic;
  },
  methods: {
    async calculateDistance() {
      await this.$refs.form.validate();
      if (!this.isValid) return;

      // travelDate - should always be YYYY-MM-DD format
      // travelTime - should always be HH:mm format
      this.travelForm.arrival = `${this.travelDate} ${this.travelTime}:00`;

      try {
        this.isLoading = true;
        const { data: { data } } = await calculateTravelInfo(this.candidate.id, this.travelForm);
        this.travelInfo = data;
      } catch (err) {
        throw new Error(err);
      } finally {
        this.isLoading = false;
      }
    },
    formatTimeTravelTextI18n(mode) {
      const arrivalDate = dayjs(this.travelForm.arrival, 'YYYY-MM-DD HH:mm:ss');
      const departureDate = arrivalDate.subtract(this.travelInfo[mode].duration, 'second');

      const timeDeparture = `${this.$t('candidate.widgets.timeTravel.departureMoment')} ${departureDate.format('llll')}`;

      const durationInMinutes = Math.abs(arrivalDate.diff(departureDate, 'minute'));
      const amountOfDays = Math.floor(durationInMinutes / 60 / 24);
      let remainingMinutes = durationInMinutes - (amountOfDays * 60 * 24);

      const amountOfHours = Math.floor(remainingMinutes / 60);
      remainingMinutes = remainingMinutes - (amountOfHours * 60);

      let durationConverted = '';
      if (amountOfDays > 0) durationConverted += `${amountOfDays} ${this.$tc('global.timeUnits.day', amountOfDays)}`;
      if (amountOfDays > 0 && amountOfHours > 0) durationConverted += ', ';
      if (amountOfHours > 0) durationConverted += `${amountOfHours} ${this.$tc('global.timeUnits.hour', amountOfHours)}`;
      if (amountOfHours > 0 || amountOfHours === 0 && amountOfDays > 0) durationConverted += ` ${this.$t('global.timeUnits.seperator')}`;
      durationConverted += ` ${remainingMinutes} ${this.$tc('global.timeUnits.minute', remainingMinutes)} `;
      durationConverted += this.$t('candidate.widgets.timeTravel.travelTime').toLowerCase();

      return [durationConverted, timeDeparture];
    },
    compareWithMaxTravelTime(mode) {
      return (this.travelInfo[mode].duration / 60) <= this.candidate.maxTravelingTime;
    },
  },
};
</script>
