<template>
  <dropdown-button
    class="mx-1"
    outlined
    @click.stop="downloadPersonal"
  >
    {{ $t('candidate.resume.downloadSpecific', { type: $t('candidate.resume.personal') }) }}
    <template #list>
      <v-list>
        <v-list-item
          link
          @click="downloadAnonymous"
        >
          {{ $t('candidate.resume.downloadSpecific', { type: $t('candidate.resume.anonymous') }) }}
        </v-list-item>
      </v-list>
    </template>
  </dropdown-button>
</template>

<script>
import downloadFile from '@/application/util/downloadFile.js';
import DropdownButton from '@/components/DropdownButton.vue';
import { getAnonymousResume, getResume } from '@/modules/candidate/api/candidate.js';

export default {
  name: 'ResumeDownloadButton',
  components: { DropdownButton },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async downloadPersonal() {
      try {
        await downloadFile(getResume(this.candidate.id), `CV_${this.candidate.name}`);
      } catch (error) {
        console.warn(error);
      }
    },
    async downloadAnonymous() {
      try {
        await downloadFile(getAnonymousResume(this.candidate.id), `CV_${this.$t('candidate.resume.anonymous')}`);
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>
