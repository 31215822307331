<template>
  <div
    :class="grid"
    class="k-field-wrapper"
  >
    <v-radio-group
      v-bind="fieldProps"
      :label="translatedLabel"
      :class="radioGroupClass"
      :rules="computedRules"
      v-on="$listeners"
    >
      <template
        v-if="clearable"
        #label
      >
        <div class="d-flex align-baseline">
          <span>{{ translatedLabel }}</span>
          <k-btn
            :disabled="!fieldProps.value"
            class="text-lowercase ml-1"
            small
            text
            @click="$emit('change', null)"
          >
            {{ $t("global.clearSelection") }}
          </k-btn>
        </div>
      </template>
      <VRadio
        v-for="(radio, index) in items"
        :key="index"
        :label="radio.text"
        :value="radio.value"
        :class="radioClass"
      />
    </v-radio-group>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';
import KBtn from '@/components/KButton.vue';

export default {
  name: 'KRadioGroup',
  components: {
    KBtn,
  },
  mixins: [Field],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    radioGroupClass: { type: String },
    radioClass: { type: String },
    items: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
