<template>
  <v-alert
    icon="$birthday"
    color="orange white--text"
    prominent
  >
    <h3
      class="display-1"
      v-html="$t('candidate.birthday.title')"
    />
    <h4
      class="subtitle-1"
      v-html="$t('candidate.birthday.message', { age: age })"
    />
  </v-alert>
</template>

<script>
export default {
  name: 'TheBirthdayMessage',
  props: {
    age: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.v-alert--prominent .v-alert__icon:after {
  background: rgba(0, 0, 0, 0);
}
</style>
