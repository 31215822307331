
interface ComponentData {
  menu: boolean,
  time: string,
  format: string
}

import Vue, { VueConstructor } from 'vue';
import Field from '@/components/crud/fields/FieldMixin.vue';
import dayjs from '@/plugins/dayjs';
import { Dayjs } from 'dayjs';

export default (Vue as VueConstructor<Vue & {
  $refs: {
    field: HTMLFormElement
  },
}>).extend({
  name: 'KTimeField',
  mixins: [Field],
  props: {
    value: {
      type: String,
    },
  },
  data: (): ComponentData => ({
    menu: false,
    time: '',
    format: 'HH:mm', // not a prop, due to inability to parse otherwise
  }),
  watch: {
    async time(value: string): Promise<void> {
      await this.$emit('input', value);
      this.$refs.field.validate();
    },
    value: {
      handler(value: string): void {
        this.time = value;
      },
      immediate: true,
    },
  },
  methods: {
    validateTime(time: string): Dayjs | string | boolean {
      if (!time) return true;
      return dayjs(time, this.format).isValid() || this.$refs?.field?.hint || '';
    },
    onInputKeyDown(e: KeyboardEvent): void {
      if (e.code === 'Space') {
        // prevent default date picker and open v-datepicker instead
        e.preventDefault();
        // this.menu = true;
      }
    },
  },
});
