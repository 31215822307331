// @ts-ignore @todo, convert api implementation into typescript
import {get, post} from '@/application/api/implementations/app';
import { AxiosPromise } from 'axios';

export const actionableCanMatchCandidate = (candidateId: number): AxiosPromise => {
  return post('actionable/candidate-can-be-matched', { candidateId });
};

export const actionableCanPlaceCandidate = (candidateId: number): AxiosPromise => {
  return post('actionable/candidate-can-be-placed', { candidateId });
};

export const actionableCanUseContractTemplate = (candidateId: number, contractTemplateId: number): AxiosPromise => {
  return post('actionable/can-use-contract-template', {
    candidateId,
    contractTemplateId,
  });
};

export const actionableCanUsePlacementTemplate = (candidateId: number, placementDocumentTemplateId: number): AxiosPromise => {
  return post('actionable/can-use-placement-document-template', {
    candidateId,
    placementDocumentTemplateId,
  });
};

export const actionableContractPossibleForPeriod = (candidateId: number, contractTypeId: number, startDate: number, endDate: number | null): AxiosPromise => {
  return get(`candidate/${candidateId}/contract/check`, {
    params: {
      contractTypeId,
      startDate,
      endDate,
    },
  });
};

export const actionableHasNoCollectiveAgreement = (employerId: number): AxiosPromise => {
  return post('actionable/employer-has-collective-agreement', { employerId });
};
