<template>
  <KPaginatedAutocomplete
    ref="autocomplete"
    :paginator="autocomplete"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { autocomplete } from '@/modules/branch/api/branch.js';

export default {
  name: 'BranchAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete(page, perPage, search, sortBy, descending, params) {
      return autocomplete(page, perPage, search, sortBy, descending, params);
    },
  },
};
</script>
