import Model from '@/application/models/model.js';

export class TravelDistance extends Model {
  destination = '';
  arrival = ''; // (Y-m-d H:i:s),
  driving_mode = null;
  transit_mode = null;

  mapForRequest() {
    return {
      destination: this.destination,
      arrival: this.arrival,
      driving_mode: this.driving_mode,
      transit_mode: this.transit_mode,
    };
  }
}
