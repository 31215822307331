import Address from '@/application/models/Address.js';
import Contact from '@/application/models/Contact';
import SelfEmployedSetting from '@/application/models/SelfEmployedSetting.js';
import Model from '@/application/models/model.js';
import formatRelationNumber from '@/application/helpers/formatRelationNumber';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class Candidate extends Model {
  address = new Address();
  assignedUsers = [];
  bigNumber = '';
  branches = [];
  bsn = '';
  canLogin = false;
  chamberOfCommerceNumber = '';
  city = '';
  companyName = '';
  contactDetail = new Contact();
  createdAt = '';
  dateOfBirth = '';
  firstName = '';
  genderId = null;
  relationNumber = 1000000;
  iban = '';
  ibanAscription = '';
  id = 0;
  initials = '';
  insertion = '';
  isZzp = false;
  lastName = '';
  maxTravelingTime = '';
  motivation = '';
  name = ''; //Just a display value for the full name, this should not be send to the API
  nationality = '';
  positions = [];
  profilePicture = null;
  rate = '';
  sector = null;
  hasPlacements = false;
  selfEmployedSetting = new SelfEmployedSetting();
  transportationCar = null;
  transportationPublic = null;
  vatNumber = '';
  hasSelfEmployedPlacement = false;
  hasTemporaryWorkerPlacement = false;

  mapForRequest() {
    return {
      address: this.address.mapForRequest(),
      assignedUsers: this.assignedUsers.map((item) => item.id),
      bigNumber: this.bigNumber,
      branches: this.branches.map((item) => item.id),
      bsn: this.bsn,
      canLogin: this.canLogin,
      chamberOfCommerceNumber: this.chamberOfCommerceNumber || undefined,
      city: this.city,
      companyName: this.companyName || undefined,
      contactDetail: this.contactDetail.mapForRequest(),
      createdAt: this.createdAt,
      dateOfBirth: this.dateOfBirth,
      firstName: this.firstName,
      genderId: this.genderId,
      iban: this.iban,
      ibanAscription: this.ibanAscription,
      initials: this.initials,
      insertion: this.insertion,
      isZzp: this.isZzp,
      lastName: this.lastName,
      maxTravelingTime: this.maxTravelingTime,
      motivation: this.motivation,
      nationality: this.nationality,
      positions: this.positions.map((item) => item.id),
      profilePicture: mapFileForRequest(this.profilePicture),
      rate: this.rate || undefined,
      sectorId: this.sector?.id ?? null,
      selfEmployedSetting: this.selfEmployedSetting.mapForRequest(),
      transportationCar: this.transportationCar,
      transportationPublic: this.transportationPublic,
      vatNumber: this.vatNumber || undefined,
    };
  }

  mapResponse(data) {
    const candidate = super.mapResponse({ ...data });
    
    candidate.transportationCar = data.transportationCar;
    candidate.transportationPublic = data.transportationPublic;
    candidate.relationNumber = formatRelationNumber(candidate.relationNumber);
    candidate.wasZzpOnOpen = data.isZzp;

    return candidate;
  }
}

export default Candidate;
