
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ActionableAlert',
  props: {
    alertType: {
      type: String,
      default: 'warning',
    },
    message: {
      type: String,
      required: true,
    },
    failureReasons: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },
});
