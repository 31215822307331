<template>
  <k-field-group language-prefix="candidate.fields">
    <k-form-dialog
      v-bind="$attrs"
      :is-update-form="isUpdateForm"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row no-gutters>
          <IntermediaryUserAutocomplete
            v-model="values.assignedUsers"
            :fill-default-user="isUpdateForm"
            field="assignedUsers"
            grid="col-sm-6 pr-2"
            multiple
          />
        </v-row>
        <v-row no-gutters>
          <KRadioGroup
            v-model="values.genderId"
            :items="genderOptions"
            field="gender"
            row
          />
          <KFileField
            v-model="values.profilePicture"
            field="profilePicture"
            accept="image/*"
          />
        </v-row>

        <v-row no-gutters>
          <KTextField
            v-model="values.initials"
            field="initials"
            grid="col-sm-6 pr-2"
          />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.firstName"
            field="firstName"
            grid="col-sm-4 pr-2"
            required
          />
          <KTextField
            v-model="values.insertion"
            field="insertion"
            grid="col-sm-2 pr-2"
          />
          <KTextField
            v-model="values.lastName"
            field="lastName"
            grid="col-sm-6 pr-2"
            required
          />
        </v-row>

        <v-row no-gutters>
          <KDateField
            v-model="values.dateOfBirth"
            :max-date="maxDate"
            :min-date="minDate"
            field="dateOfBirth"
            grid="col-sm-12 pr-2"
          />

          <KTextField
            v-model="values.nationality"
            field="nationality"
            grid="col-sm-6 pr-2"
          />

          <KTextarea
            v-model="values.motivation"
            field="motivation"
            grid="col-sm-12 pr-2"
          />
        </v-row>
      </template>
      <template #panel.1>
        <v-row
          no-gutters
          class="pr-2"
        >
          <v-col
            sm="6"
            class="pr-3"
          >
            <Address
              v-model="values.address"
              :required="false"
            />
          </v-col>
          <v-col
            sm="6"
            class="pl-3"
          >
            <Contact
              v-model="values.contactDetail"
              :fields="contactFields"
            />
          </v-col>
        </v-row>
      </template>
      <template #panel.2>
        <v-row no-gutters>
          <KTextField
            v-model="values.maxTravelingTime"
            :suffix="$tc('global.timeUnits.minute', values.maxTravelingTime)"
            field="maxTravelingTime"
            grid="col-lg-6 pr-2"
            step="1"
            type="number"
          />
        </v-row>
        <v-row no-gutters>
          <div class="v-label col-sm-12 pr-2">
            {{ $t('candidate.fields.transportationMethod') }}
          </div>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <KRadioGroup
              v-model="values.transportationCar"
              :items="booleanOptions"
              field="transportationCar"
              class="pr-2"
              row
            />
            <KRadioGroup
              v-model="values.transportationPublic"
              :items="booleanOptions"
              field="transportationPublic"
              row
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <SectorAutocomplete
            v-model="values.sector"
            field="sector"
            grid="col-sm-6"
            @change="handleSectorChange"
          />
        </v-row>
        <v-row
          no-gutters
        >
          <PositionAutocomplete
            v-if="values.sector"
            v-model="values.positions"
            :sector-id="values.sector.id"
            field="position"
            grid="col-sm-6"
            multiple
          />
        </v-row>
        <v-row no-gutters>
          <BranchAutocomplete
            v-if="values.sector"
            v-model="values.branches"
            field="branch"
            grid="col-sm-6"
            multiple
          />
        </v-row>
      </template>

      <template #panel.3>
        <v-row v-if="isUpdateForm && values.relationNumber">
          <v-col sm="6">
            <KTextField
              v-model="values.relationNumber"
              field="relationNumber"
              disabled
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.bsn"
            :rules="[restrictedCharacters]"
            field="bsn"
            grid="col-sm-6"
          />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.iban"
            :rules="[validIban]"
            field="iban"
            grid="col-sm-6"
          />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.ibanAscription"
            field="ibanAscription"
            grid="col-sm-6"
          />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.bigNumber"
            field="bigNumber"
            type="number"
            step="1"
            grid="col-sm-6"
          />
        </v-row>
      </template>

      <template #panel.4>
        <v-row no-gutters>
          <v-tooltip
            :disabled="!candidateIsActiveZzp"
            right
          >
            <template #activator="{ on }">
              <div v-on="on">
                <KCheckbox
                  v-model="values.isZzp"
                  :disabled="candidateIsActiveZzp"
                  field="isZzp"
                />
              </div>
            </template>
            <span>{{ $t('candidate.messages.hasActiveZzpPlacement') }}</span>
          </v-tooltip>
        </v-row>
        <template v-if="values.isZzp">
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <KTextField
                v-model="values.selfEmployedSetting.companyName"
                field="selfEmployedSetting.companyName"
              />
              <KTextField
                v-model="values.selfEmployedSetting.vatNumber"
                field="vatNumber"
              />
              <KTextField
                v-model="values.selfEmployedSetting.chamberOfCommerceNumber"
                :rules="[validChamberOfCommerceNumber]"
                required
                field="selfEmployedSetting.chamberOfCommerceNumber"
              />
              <KTextField
                v-model="values.rate"
                :suffix="$t('global.euro')"
                field="rate"
                type="number"
              />
            </v-col>
            <v-col>
              <Address
                v-model="values.selfEmployedSetting.address"
                :required="false"
                field="selfEmployedSetting.companyAddress"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col sm="6">
              <KTextField
                v-model="values.selfEmployedSetting.iban"
                :rules="[validIban]"
                required
                field="selfEmployedSetting.iban"
              />
            </v-col>
            <v-col cols="6">
              <KTextField
                v-model="values.selfEmployedSetting.ibanAscription"
                required
                field="selfEmployedSetting.ibanAscription"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <KRadioGroup
                v-model.number="values.selfEmployedSetting.invoiceBillingIntervalId"
                :items="billingIntervalOptions"
                :required="values.hasPlacements"
                :clearable="!values.hasPlacements"
                field="selfEmployedSetting.billingIntervalOptions"
              />
            </v-col>
          </v-row>
        </template>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import Candidate from '@/application/models/Candidate.js';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import Address from '@/modules/address/components/AddressForm.old.vue';
import BranchAutocomplete from '@/modules/branch/components/BranchAutocomplete.vue';
import Contact from '@/modules/contactDetail/components/ContactForm.vue';
import IntermediaryUserAutocomplete from '../../intermediaryUser/components/IntermediaryUserAutocomplete.vue';
import PositionAutocomplete from '@/modules/position/components/PositionAutocomplete.vue';
import SectorAutocomplete from '@/modules/sectors/components/SectorAutocomplete.vue';
import dayjs from '@/plugins/dayjs';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import { invoiceBillingIntervalType } from '@/application/enums/invoiceBillingIntervalType.js';
import validIban from '@/application/util/inputFieldRules/validIban';
import validChamberOfCommerceNumber from '@/application/util/inputFieldRules/validChamberOfCommerceNumber';

export default {
  name: 'CandidateForm',
  components: {
    KCheckbox,
    BranchAutocomplete,
    PositionAutocomplete,
    SectorAutocomplete,
    KTextarea,
    Contact,
    KDateField,
    KTextField,
    KFileField,
    KRadioGroup,
    IntermediaryUserAutocomplete,
    Address,
    KFieldGroup,
    KFormDialog,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
    },
    values: {
      type: Object,
      default: new Candidate(),
    },
  },
  data: () => ({
    contactFields: [
      {
        name: 'email',
      },
      {
        name: 'linkedInUrl',
      },
      {
        name: 'phone',
        settings: { required: true },
      },
      {
        name: 'mobilePhone',
      },
      {
        name: 'website',
      }],
  }),
  computed: {
    panels() {
      return [
        { name: this.$t('candidate.tabs.personal') },
        { name: this.$t('candidate.tabs.contact') },
        { name: this.$t('candidate.tabs.wishes') },
        { name: this.$t('candidate.tabs.admin') },
        { name: this.$t('candidate.tabs.company') },
      ];
    },
    genderOptions() {
      return [
        {
          text: this.$t('candidate.genderOptions.1'),
          value: 1,
        }, {
          text: this.$t('candidate.genderOptions.2'),
          value: 2,
        }, {
          text: this.$t('candidate.genderOptions.3'),
          value: 3,
        }];
    },
    booleanOptions() {
      return [
        {
          text: this.$t('global.booleanOptions.yes'),
          value: true,
        },
        {
          text: this.$t('global.booleanOptions.no'),
          value: false,
        },
      ];
    },
    minDate() {
      return dayjs().subtract(75, 'year');
    },
    maxDate() {
      return dayjs();
    },
    billingIntervalOptions() {
      return [
        {
          value: invoiceBillingIntervalType.WEEK,
          text: this.$t('employerSettings.invoiceBillingIntervalValues.WEEK'),
        },
        {
          value: invoiceBillingIntervalType.MONTH,
          text: this.$t('employerSettings.invoiceBillingIntervalValues.MONTH'),
        },
      ];
    },
    candidateIsActiveZzp(){
      return this.values.wasZzpOnOpen && this.values.hasSelfEmployedPlacement;
    },
  },
  watch: {
    'values.isZzp'(val) {
      if (val) this.prefillSelfEmployedData();
    },
  },
  methods: {
    validIban,
    validChamberOfCommerceNumber,
    prefillSelfEmployedData() {
      const { street, zipCode, city } = this.values.selfEmployedSetting.address;

      if (!street && !zipCode && !city) {
        this.values.selfEmployedSetting.address = this.values.address;
      }

      ['iban', 'ibanAscription'].forEach(field => {
        if (!this.values.selfEmployedSetting[field]) {
          this.values.selfEmployedSetting[field] = this.values[field];
        }
      });
    },
    restrictedCharacters(input) {
      const regex = /^\d{8,9}$/;
      return !input || regex.test(input) || this.$t('candidate.messages.restrictedCharacters');
    },
    handleSectorChange() {
      this.values.positions = [];
    },
  },
};
</script>
