import { render, staticRenderFns } from "./SectorAutocomplete.vue?vue&type=template&id=65c16d7a&"
import script from "./SectorAutocomplete.vue?vue&type=script&lang=js&"
export * from "./SectorAutocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports