<template>
  <KAutocomplete
    :items="sectors"
    v-bind="$attrs"
    item-text="name"
    return-object
    v-on="$listeners"
  />
</template>

<script>
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SectorAutocomplete',
  components: { KAutocomplete },
  computed: {
    ...mapGetters({ sectors: 'sectors/all' }),
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions({ fetch: 'sectors/fetch' }),
  },
};
</script>
