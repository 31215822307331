<template>
  <PageLayout>
    <template #header>
      <k-title>{{ candidate.name }}</k-title>
    </template>
    <template #flow-actions>
      <ResumeDownloadButton :candidate="candidate" />
      <v-btn
        class="mx-1"
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('candidate.title') }) }}
      </v-btn>
      <CandidateForm
        v-model="updateDialog"
        :request="updateRequest"
        :title="$tc('candidate.title')"
        :values="updateFormValues"
        is-update-form
        @change="onCandidateChange"
      />
    </template>
    <template
      v-if="candidate"
      #default
    >
      <v-row v-if="candidateCanMatchFailureReasons.length > 0">
        <v-col cols="12">
          <actionable-alert
            :message="$t('actionables.messages.canMatchActionableFailed')"
            :failure-reasons="candidateCanMatchFailureReasons"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <k-dossier>
            <TheBirthdayMessage
              v-if="isTodayBirthday"
              :age="age"
            />
            <k-display-group language-prefix="candidate.fields">
              <v-row no-gutters>
                <v-col cols="8">
                  <k-text-display
                    v-if="candidate.relationNumber"
                    field="relationNumber"
                  >
                    {{ candidate.relationNumber }}
                  </k-text-display>
                  <KLinkDisplay
                    :href="candidate.contactDetail.phone"
                    field="contactDetail.phone"
                    link-type="phone"
                  />
                  <KLinkDisplay
                    :href="candidate.contactDetail.mobilePhone"
                    field="contactDetail.mobilePhone"
                    link-type="phone"
                  />
                  <KLinkDisplay
                    :href="candidate.contactDetail.email"
                    field="contactDetail.email"
                    link-type="email"
                  />
                  <k-text-display field="address">
                    <AddressDisplay :address="candidate.address" />
                  </k-text-display>
                </v-col>
                <v-col cols="4">
                  <VImg
                    v-if="candidate.profilePicture"
                    :src="`${candidate.profilePicture.url}&type=small`"
                    :lazy-src="`${candidate.profilePicture.url}&type=lazy`"
                    class="mb-3"
                    width="200"
                  />
                  <k-text-display
                    v-if="candidate.genderId"
                    :icon="genderIcon"
                  >
                    {{ $t(`candidate.genderOptions.${candidate.genderId}`) }}
                  </k-text-display>
                  <k-text-display
                    v-if="age"
                    icon="$birthday"
                  >
                    {{ age }} {{ $t('global.timeUnits.birthdayYears') }}
                  </k-text-display>
                </v-col>
              </v-row>
              <v-row
                v-if="candidate.motivation"
                no-gutters
              >
                <v-col>
                  <k-text-display field="motivation">
                    {{ candidate.motivation }}
                  </k-text-display>
                </v-col>
              </v-row>
            </k-display-group>
          </k-dossier>
        </v-col>
        <v-col cols="6">
          <TravelDistance :candidate="candidate" />
          <InternalNote
            v-if="can('candidate.internalNote')"
            scope="candidate"
            :scope-id="candidate.id"
          />
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Candidate from '@/application/models/Candidate.js';
import { genderToIcon } from '@/application/util/gender.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import TheBirthdayMessage from '@/modules/candidate/components/TheBirthdayMessage.vue';
import InternalNote from '@/modules/internalNote/components/InternalNote.vue';
import TravelDistance from '@/components/widgets/candidate/TravelDistance.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import { invite, show, update } from '@/modules/candidate/api/candidate.js';
import CandidateForm from '@/modules/candidate/components/CandidateForm.vue';
import ResumeDownloadButton from '@/modules/candidate/components/ResumeDownloadButton.vue';
import dayjs from '@/plugins/dayjs';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';
import { mapGetters } from 'vuex';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';
import { actionableCanMatchCandidate } from '@/modules/actionable/api/index.ts';

export default {
  name: 'CandidateDossier',
  components: {
    ActionableAlert,
    KDossier,
    KTitle,
    TheBirthdayMessage,
    TravelDistance,
    ResumeDownloadButton,
    AddressDisplay,
    KLinkDisplay,
    KDisplayGroup,
    CandidateForm,
    PageLayout,
    KTextDisplay,
    InternalNote,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  data: () => ({
    updateFormValues: new Candidate(),
    updateDialog: false,
    isSending: false,
    alertType: 'success',
    alertMessage: '',
    candidateCanMatchFailureReasons: [],
  }),
  computed: {
    ...mapGetters('authorisation', ['can']),
    genderIcon() {
      return genderToIcon(this.candidate.genderId);
    },
    isTodayBirthday() {
      return dayjs().format('MM-DD') === dayjs(this.candidate.dateOfBirth).format('MM-DD');
    },
    age() {
      if (!this.candidate.dateOfBirth) {
        return null;
      }
      return dayjs().diff(dayjs(this.candidate.dateOfBirth), 'year');
    },
  },
  watch: {
    '$route.params.candidateId': {
      immediate: true,
      handler: async function () {
        if (this.can('candidate.internalNote')) {
          eventBus.$emit('internalNote', {
            scope: 'candidate',
            scopeId: this.candidate.id,
          });
        }
        await this.checkCanBeMatchedActionable();
      },
    },
    candidate: {
      immediate: true,
      handler() {
        eventBus.$emit('setBreadcrumbs', [
          {
            exact: true,
            to: { name: 'candidate.index' },
            text: this.$tc('candidate.title', 2),
          },
          {
            exact: true,
            to: { name: 'candidate.show' },
            text: this.candidate.name,
          }],
        );
      },
    },
  },
  beforeDestroy() {
    eventBus.$emit('internalNote');
  },
  methods: {
    onCandidateChange() {
      this.getCandidate();
      this.checkCanBeMatchedActionable();
    },
    async checkCanBeMatchedActionable() {
      // check if candidate data is sufficient for matching process
      const response = await actionableCanMatchCandidate(this.candidate.id);
      this.candidateCanMatchFailureReasons = response.data.reasons;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate() {
      this.updateFormValues = new Candidate();
      const response = await show(this.candidate.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
    async sendInvitation() {
      if (this.isSending) return;

      this.isSending = true;
      try {
        await invite(this.candidate.id);
        this.alertMessage = this.$t('candidate.dossier.invitationSendSuccess');
        this.alertType = 'success';
      } catch (error) {
        this.alertMessage = this.$t('candidate.dossier.invitationSendError');
        this.alertType = 'error';
      }
      this.getCandidate();
      setTimeout(() => this.alertMessage = '', 3000);
      this.isSending = false;
    },
  },
};
</script>
