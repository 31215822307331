import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('branch', page, perPage, search, sortBy, descending, params);
}

function autocomplete(page, perPage, search, sortBy, descending, params) {
  return getPaginated('branch/autocomplete', page, perPage, search, sortBy, descending, params);
}

/**
 * @param branchId {number}
 */
function show(branchId) {
  return get(`branch/${branchId}`);
}

/**
 * @param branch {Branch}
 */
function create(branch) {
  return post('branch', branch);
}

/**
 * @param branch {Branch}
 */
function update(branch) {
  return put(`branch/${branch.id}`, branch);
}

/**
 * @param branchId {number}
 */
function remove(branchId) {
  return destroy(`branch/${branchId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
  autocomplete,
};
