<template>
  <KPaginatedAutocomplete
    ref="autocomplete"
    :paginator="autocomplete"
    :multiple="multiple"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { autocomplete } from '@/modules/position/api/position.js';

export default {
  name: 'PositionAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    sectorId: {
      type: Number,
    },
    multiple: {
      type: Boolean,
    },
  },
  watch: {
    sectorId() {
      const emitValue = this.multiple ? [] : undefined;
      this.$refs.autocomplete.refresh();
      this.$emit('input', emitValue);
    },
  },
  methods: {
    autocomplete(page, perPage, search, sortBy, descending, params) {
      return autocomplete(page, perPage, search, sortBy, descending, {
        ...params,
        sectorId: this.sectorId,
      });
    },
  },
};
</script>
