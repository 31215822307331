import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (...args) =>
  getPaginated('candidate', ...args);

export const autocomplete = (...args) =>
  getPaginated('candidate/autocomplete', ...args);

/**
 * @param candidateId {number}
 */
export const show = (candidateId) =>
  get(`candidate/${candidateId}`);

/**
 * @param candidateId {number}
 */
export const getResume = (candidateId) =>
  get(`candidate/${candidateId}/cv`, { responseType: 'blob' });

/**
 * @param candidateId {number}
 */
export const getAnonymousResume = (candidateId) =>
  get(`candidate/${candidateId}/cv/anonymous`, { responseType: 'blob' });

/**
 * @param candidateId {number}
 * @param formData {TravelDistance}
 */
export const calculateTravelInfo = (candidateId, formData) =>
  post(`widget/candidate/${candidateId}/distance/calculate`, formData);

/**
 * @param candidate {Candidate}
 */
export const create = (candidate) =>
  post('candidate', candidate);

/**
 * @param candidate {Candidate}
 */
export const update = (candidate) =>
  put(`candidate/${candidate.id}`, candidate);

/**
 * @param candidateId {number}
 */
export const remove = (candidateId) =>
  destroy(`candidate/${candidateId}`);

/**
 * @param candidateId {number}
 */
export const invite = (candidateId) => post(`candidate/${candidateId}/invitation/resend`);
