import Model from '@/application/models/model.js';
import Address from './Address.js';

class SelfEmployedSetting extends Model {
  address = new Address();
  chamberOfCommerceNumber = '';
  companyName = '';
  iban = '';
  ibanAscription = '';
  vatNumber = '';
  invoiceBillingIntervalId = null;
  hasManualAddress = true;

  mapForRequest() {
    return {
      address: this.address.mapForRequest(),
      chamberOfCommerceNumber: this.chamberOfCommerceNumber,
      companyName: this.companyName,
      iban: this.iban,
      ibanAscription: this.ibanAscription,
      vatNumber: this.vatNumber,
      invoiceBillingIntervalId: this.invoiceBillingIntervalId,
      hasManualAddress: this.hasManualAddress,
    };
  }
}

export default SelfEmployedSetting;
